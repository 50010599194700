<template>
  <el-card v-loading="isLoading" shadow="never" class="allow-portrait">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col
          ><el-row type="flex" justify="center"
            ><h4>Recibo de Pagamento</h4></el-row
          ></el-col
        >
      </el-row>
    </template>

    <!--<el-row type="flex" class="m-0" justify="space-between">
      <h6 class="m-0">Produtor:</h6>
      <h6 class="m-0">ANTONIO DE OLIVEIRA SOUZA</h6>
    </el-row>

    <el-row type="flex" class="m-0" justify="space-between">
      <h6 class="m-0">Nº Romaneio:</h6>
      <h6 class="m-0">00590</h6>
    </el-row>

    <el-row type="flex" class="m-0" justify="space-between">
      <h6 class="m-0">Data do Romaneio:</h6>
      <h6 class="m-0">25/05/2021</h6>
    </el-row>

    <el-row type="flex" class="m-0" justify="space-between">
      <h6 class="m-0">Peso Total:</h6>
      <h6 class="m-0">2.590,50 kg.</h6>
    </el-row>-->
    <el-descriptions
      class="margin-top"
      title="Dados do Romaneio"
      :column="1"
      :size="size"
      border
    >
      <el-descriptions-item>
        <template #label> Produtor: </template>
        {{ Data.provider?.name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Nº Romaneio: </template>
        {{ Data.code }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Data do Romaneio: </template>
        {{ dateFormatter.format(new Date(Data.created_at || Date.now())) }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Peso Total: </template>
        {{ numberFormat.format(SumAllWeights || 0) }} kg.
      </el-descriptions-item>
    </el-descriptions>

    <h5>Produtos:</h5>

    <el-row v-if="Report">
      <el-col>
        <el-table
          size="mini"
          stripe
          header-cell-style="text-align: center;"
          cell-class-name="text-center"
          :cell-style="() => 'text-align:center;'"
          :data="Report"
          style="width: 100%; z-index: 0"
          class="print-entire-table"
          v-if="Report"
        >
          <el-table-column width="60" prop="quantity" label="quant.">
          </el-table-column>
          <el-table-column
            witdh="75"
            prop="total_weight"
            label="peso bruto"
            :formatter="(r) => `${numberFormat.format(r.total_weight)} kg.`"
          >
          </el-table-column>
          <el-table-column
            witdh="70"
            label="% resf."
            prop="deduction"
            :formatter="(r) => `${r?.deduction} %`"
          >
          </el-table-column>
          <el-table-column
            label="peso liquido"
            prop="weight"
            :formatter="(r) => `${numberFormat.format(r.weight || 0)} kg.`"
          >
          </el-table-column>
          <el-table-column label="classificação" prop="standard">
          </el-table-column>
          <el-table-column
            label="preço kg."
            :formatter="(r) => formatCurrency(r.price)"
          >
          </el-table-column>
          <el-table-column
            label="preço total"
            prop="total_price"
            :formatter="(r) => formatCurrency(r.total_price)"
          >
          </el-table-column>
        </el-table>
        <el-row type="flex" justify="end">
          <el-col :md="16"></el-col>
          <el-col :md="8" class="md-8-print">
            <el-row type="flex" justify="space-between">
              <el-col :md="12" class="md-12-print">
                <h5 class="m-0">Total Parcial:</h5>
              </el-col>
              <el-col :md="12" class="md-12-print">
                <el-row type="flex" justify="center">
                  <h5 class="m-0">
                    {{ currencyFormatter.format(SumTotalPrice || 0) }}
                  </h5>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row type="flex" justify="end">
          <el-col :md="16"></el-col>
          <el-col :md="8" class="md-8-print">
            <el-row type="flex" justify="space-between">
              <el-col :md="12" class="md-12-print">
                <h5 class="m-0">Desconto Abate:</h5>
              </el-col>
              <el-col :md="12" class="md-12-print">
                <el-row type="flex" justify="center">
                  <h5 class="text-red m-0">
                    {{ currencyFormatter.format(SumTotalTax) }}
                  </h5>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row type="flex" justify="end">
          <el-col :md="16"></el-col>
          <el-col :md="8" class="md-8-print">
            <el-row type="flex" justify="space-between">
              <el-col :md="12" class="md-12-print">
                <h5 class="m-0">Valor Total:</h5>
              </el-col>
              <el-col :md="12" class="md-12-print">
                <el-row type="flex" justify="center">
                  <h5 class="m-0">
                    {{ currencyFormatter.format(SumTotalPrice + SumTotalTax) }}
                  </h5>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row justify="center" v-else>
      <el-col :md="24">
        <h5>Nenhum animal cadastrado no romaneio</h5>
      </el-col>
    </el-row>

    <el-divider></el-divider>

    <el-row type="flex" class="row-assign" justify="space-around">
      <el-col :xs="12" :md="12" class="md-12-print">
        <el-card shadow="never" style="height: 200px">
          <el-row type="flex" justify="center" style="margin-top: 90px">
            <el-col align="center">
              <h5 class="m-0">_____________________________________________</h5>
              <h6 class="m-0">ASSINATURA DO CONFERENTE</h6>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :xs="12" :md="12" class="md-12-print">
        <el-card shadow="never" style="height: 200px">
          <h6>PAGO EM: ____/____/_______</h6>
          <el-row type="flex" justify="center" style="margin-top: 50px">
            <el-col align="center">
              <h5 class="m-0">_____________________________________________</h5>
              <h6 class="m-0">ASSINATURA DO PRODUTOR</h6>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
//import Moment from "moment";
//import { ElNotification } from "element-plus";
export default {
  name: "BillsPage",
  data: () => ({
    reports: null,
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    numberFormat: new Intl.NumberFormat("pt-BR", {
      minimumFractionDigits: 2,
      style: "decimal",
    }),
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    }),
  }),
  computed: {
    Data() {
      return JSON.parse(this.$route?.query?.data || "{}");
    },
    Provider() {
      return this.$route.params?.provider || {};
    },
    Packing() {
      return this.Data || {};
    },
    SumAllWeights() {
      return this.Packing?.animals?.reduce(
        (t, a) => (t += Number(a.weight || 0) + Number(a.weight_ || 0)),
        0
      );
    },
    SumTotalPrice() {
      return this.Report?.reduce((t, e) => (t += e.total_price), 0);
    },
    SumTotalTax() {
      return (
        -1 * Number(this.Data.total) * Number(this.Data.provider?.tax) || 0
      );
    },
    Report() {
      const deduction = Number(this.Data?.provider?.deduction) || 0;
      return Object.values(
        this.Data?.animals?.reduce((t, a) => {
          if (t[a.standard + a.price]) {
            const _ = t[a.standard + a.price];
            _.quantity++;
            _.total_weight +=
              (Number(a.weight) || 0) + (Number(a.weight_) || 0);
            _.weight = _.total_weight * (1 - Number(deduction) / 100);
            _.total_price = _.weight * _.price;
          } else {
            t[a.standard + a.price] = {
              quantity: 1,
              total_weight: (Number(a.weight) || 0) + (Number(a.weight_) || 0),
              deduction: deduction,
              weight:
                ((Number(a.weight) || 0) + (Number(a.weight_) || 0)) *
                (1 - Number(deduction) / 100),
              standard: a.standard,
              price: Number(a.price),
              total_price:
                ((Number(a.weight) || 0) + (Number(a.weight_) || 0)) *
                (1 - Number(deduction) / 100) *
                Number(a.price),
            };
          }

          return t;
        }, {}) || {}
      );
    },
  },

  methods: {
    formatCurrency(c) {
      return c ? this.currencyFormatter.format(c) : "R$ 0,00";
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
  },
};
</script>
<style>
body {
  -webkit-print-color-adjust: exact;
}
.el-card {
  width: 100%;
}
.p-0 {
  padding: 0px !important;
}
.text-red {
  color: red;
}
.m-0 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.text-center {
  text-align: center !important;
}

@media print {
  table.el-table__body {
    width: auto !important;
    table-layout: unset !important;
  }
  table.el-table__header {
    width: auto !important;
    table-layout: unset !important;
  }
  div.md-12-print {
    max-width: 49% !important;
  }
  div.md-8-print {
    max-width: 33% !important;
  }
  html,
  body {
    height: auto;
  }
}

.allow-portrait {
  @page {
    size: portrait !important;
  }
}

.allow-landscape {
  @page {
    size: landscape !important;
  }
}

.cell {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  width: 100%;
  justify-content: center;
}
</style>